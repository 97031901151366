import { useEffect, useRef } from 'react';
import Container from '../../elements/Container';
import Spacer from '../../elements/Spacer';
import { useAppContext } from '../../hooks/useAppContext';
import useScript from '../../hooks/useScript';
import { px2Rem } from '../../utils/px2Rem';
import { StyledTrustPilotBanner, StyledTrustPilotBannerHeader } from './styles';
import { ITrustPilotBanner } from './types';
import { palette } from '@raylo-tech/raylopay-ui';

export const TrustPilotBanner = ({ enableBorderBottom, dataTestId }: ITrustPilotBanner) => {
  const { isRayloPay } = useAppContext();
  const [loaded] = useScript('//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js');

  // Create a reference to the <div> element which will represent the TrustBox
  const ref = useRef(null);

  useEffect(() => {
    if (loaded && window.Trustpilot) {
      window.Trustpilot.loadFromElement(ref.current, true);
    }
  }, [loaded]);

  return (
    <StyledTrustPilotBanner $enableBorderBottom={enableBorderBottom}>
      <Container dataTestId={dataTestId} center height={px2Rem(350)}>
        <Container
          maxWidth="1200px"
          center
          styles={{
            textAlign: 'center',
          }}
        >
          <StyledTrustPilotBannerHeader>
            4.5 stars across 7000+ Trustpilot reviews.{' '}
            <span
              style={{
                color: palette.charcoal[300],
              }}
            >
              Thousands are joining Raylo {isRayloPay && 'Pay'} every month.
            </span>
          </StyledTrustPilotBannerHeader>
          <Spacer height={32} />
          <div
            ref={ref}
            className="trustpilot-widget"
            data-testid={dataTestId}
            data-locale="en-GB"
            data-template-id="53aa8912dec7e10d38f59f36"
            data-businessunit-id="5cdd6c38b4acc6000128faf9"
            data-style-height="240px"
            data-style-width="100%"
            data-theme="dark"
            data-stars="4,5"
            data-review-languages="en"
            style={{
              position: 'relative',
              height: '140px',
              width: '110%',
              borderStyle: 'none',
              display: 'block',
              overflow: 'hidden',
            }}
          >
            <a href="https://uk.trustpilot.com/review/www.raylo.com" rel="noopener">
              Trustpilot
            </a>
          </div>
        </Container>
      </Container>
    </StyledTrustPilotBanner>
  );
};
