import { ReactNode, useEffect } from 'react';
import { useCustomerContext } from '../../hooks/useCustomerContext';
import { useQuery } from '@apollo/client';
import { useIntercom } from 'react-use-intercom';
import { GET_INTERCOM } from '@/graphql/operations/intercom';

const IntercomWrapper = ({ children }: { children: ReactNode }) => {
  const { hasLoggedInCustomer } = useCustomerContext();
  const { boot, update } = useIntercom();

  useEffect(() => {
    boot();
  }, [boot]);

  useQuery(GET_INTERCOM, {
    skip: !hasLoggedInCustomer,
    onCompleted: (data) => {
      if (data?.customer?.messagingDetails?.intercom && data.customer.email) {
        const { userHash, userId } = data.customer.messagingDetails.intercom;
        update({
          userHash: userHash,
          userId: userId,
          email: data.customer.email,
        });
      }
    },
  });

  return <>{children}</>;
};

export default IntercomWrapper;
