import NEW_BREAKPOINTS from '@/constants/breakpoints';
import { px2Rem } from '../../utils/px2Rem';
import styled from 'styled-components';
import { palette } from '@raylo-tech/raylopay-ui';

const StyledBenefitsInfo = styled.div<{ backgroundColor?: string }>`
  background-color: ${({ backgroundColor }) => (backgroundColor ? backgroundColor : palette.white)};
  svg g,
  svg path {
    stroke: ${({ theme }) => theme?.benefitsInfo?.iconColor};
  }

  @media (min-width: ${NEW_BREAKPOINTS.tablet}px) {
    padding: ${px2Rem(16)};
  }
`;

const StyledBenefitsInfoItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-right: ${px2Rem(32)};
  padding-left: ${px2Rem(32)};
  text-align: center;

  @media (min-width: ${NEW_BREAKPOINTS.tablet}px) {
    padding-right: 0;
    padding-left: 0;
    text-align: left;
    align-items: flex-start;
  }
`;

export { StyledBenefitsInfo, StyledBenefitsInfoItem };
