import styled from 'styled-components';
import { px2Rem } from '../../utils/px2Rem';
import NEW_BREAKPOINTS from '@/constants/breakpoints';
import { palette } from '@raylo-tech/raylopay-ui';

export const StyledAccountBanner = styled.div`
  background: ${palette.blue[500]};

  .upgrade-subscription__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    padding: ${px2Rem(40)} ${px2Rem(24)};
    max-width: ${px2Rem(NEW_BREAKPOINTS.desktop)};

    @media (max-width: ${NEW_BREAKPOINTS.smallDesktop}px) {
      padding: ${px2Rem(24)} ${px2Rem(24)};
    }
  }

  p span {
    color: ${palette.pink[300]};
  }
`;

export const StyledBackButton = styled.button`
  text-decoration: none;
  display: flex;
  background: transparent;
  border: none;
  align-items: center;
  cursor: pointer;

  img {
    margin-right: ${px2Rem(10)};
  }
`;
