import Container from '../../elements/Container';
import Copy from '../../elements/Copy';
import Divider from '../../elements/Divider';
import Icon from '../../elements/Icon';
import Spacer from '../../elements/Spacer';
import { useAppContext } from '../../hooks/useAppContext';
import { ILink, ILinks, useContentContext } from '../../hooks/useContentContext';
import { px2Rem } from '../../utils/px2Rem';
import {
  HeaderWrapper,
  StyledFooter,
  StyledFooterBaselineContainer,
  StyledFooterCopyrightContainer,
  StyledFooterMainContainer,
  StyledFooterOuterContainer,
  StyledFooterSocialContainer,
  StyledLink,
  StyledLinkWrapper,
} from './styles';
import { IFooter } from './types';
import { Link } from '../../elements/Link/Link';
import { palette } from '@raylo-tech/raylopay-ui';

export const Footer = ({ dataTestId }: IFooter) => {
  const { isRayloPay } = useAppContext();
  const { footerListOne, footerListTwo, footerSocialMediaLinks, trustPilotFooterLogo } =
    useContentContext();

  const { title: listOneTitle, links: listOneLinks }: ILinks = footerListOne;
  const { title: listTwoTitle, links: listTwoLinks }: ILinks = footerListTwo;

  return (
    <StyledFooter data-testid={dataTestId}>
      <StyledFooterOuterContainer>
        <StyledFooterMainContainer>
          <Container flexRow>
            <Container>
              <HeaderWrapper>
                <Copy color={palette.white} fontSize={14}>
                  {listOneTitle}
                </Copy>
              </HeaderWrapper>
              <Spacer height={24} />
              {listOneLinks?.map(({ label, link }: ILink) => (
                <div key={link}>
                  {isRayloPay ? (
                    <StyledLinkWrapper>
                      <Link href={link} passHref>
                        {label}
                      </Link>
                    </StyledLinkWrapper>
                  ) : (
                    <StyledLink href={link}>{label}</StyledLink>
                  )}
                  <Spacer height={12} />
                </div>
              ))}
            </Container>
            <Container>
              <HeaderWrapper>
                <Copy color={palette.white} fontSize={14}>
                  {listTwoTitle}
                </Copy>
              </HeaderWrapper>
              <Spacer height={24} />
              {listTwoLinks?.map(({ label, link }: ILink) => (
                <div key={link}>
                  <StyledLink href={link} className="launch-intercom">
                    {label}
                  </StyledLink>
                  <Spacer height={12} />
                </div>
              ))}
            </Container>
          </Container>

          <Container>
            <Container maxWidth={px2Rem(263)}>
              <Spacer height={64} />
              {isRayloPay && <Icon name="PoweredByRayloPayLogo" dataTestId="raylo-pay-logo" />}
              {!isRayloPay && <Icon name="Logo" />}
              <Spacer height={32} />
              <Copy fontSize={11} color={palette.charcoal[300]} lineHeight={18}>
                Raylo Group Limited is authorised and regulated by the Financial Conduct Authority
                (841488).
              </Copy>
              <Spacer height={16} />
              <Copy fontSize={11} color={palette.charcoal[300]} lineHeight={18}>
                Raylo is a trading name of Raylo Group Limited. Raylo Group Limited is registered in
                England and Wales company number 11554120.
              </Copy>
            </Container>
            <Spacer height={24} />
            {!isRayloPay && (
              <Container>
                <Copy fontSize={11} color={palette.charcoal[300]} bold>
                  Head Office
                </Copy>
                <Spacer height={4} />
                <Copy fontSize={11} color={palette.charcoal[300]} lineHeight={18}>
                  5 New Street Square, City of London, EC4A 3TW
                </Copy>
              </Container>
            )}
          </Container>
        </StyledFooterMainContainer>

        <Container maxWidth={px2Rem(1200)}>
          <Divider width="100%" color={palette.charcoal[400]} opacity="1" padding={0} />
          <Spacer height={12} />
          <StyledFooterBaselineContainer>
            {!isRayloPay && (
              <StyledFooterSocialContainer>
                {footerSocialMediaLinks.map(({ icon, link }) => (
                  <a
                    key={link}
                    href={link}
                    target="_blank"
                    rel="noreferrer"
                    title={`Raylo on ${icon}`}
                  >
                    <Icon name={icon} />
                  </a>
                ))}
              </StyledFooterSocialContainer>
            )}

            {isRayloPay && (
              <Container width="fit-content" flexRow>
                <Container center margin="-4px 0 0 0">
                  <Copy color="white" lineHeight={28}>
                    Excellent
                  </Copy>
                </Container>
                <Container margin="0 0 0 8px">
                  <img src={trustPilotFooterLogo} alt="Trustpilot logo" height={24} />
                </Container>
                <Container margin="0 0 0 8px">
                  <Icon name="Trustpilot2" />
                </Container>
              </Container>
            )}

            <StyledFooterCopyrightContainer>
              <Copy color={palette.charcoal[300]} fontSize={11}>
                © Copyright of Raylo {new Date().getFullYear()}. All rights reserved.
              </Copy>
            </StyledFooterCopyrightContainer>
          </StyledFooterBaselineContainer>
        </Container>
      </StyledFooterOuterContainer>
    </StyledFooter>
  );
};
