import React, { useState } from 'react';
import { ExistingCustomerNavBarProps } from './ExistingCustomerNavBar.types';
import {
  AccountHeader,
  AccountHeaderNav,
  NavLink,
  NavToggle,
  NavToggleIcon,
} from './ExistingCustomerNavBar.styles';
import { ASSETS_SVGS_BASE_URL } from '../../constants';
import { useMediaLayout } from 'use-media';

export const ExistingCustomerNavBar = ({
  dataTestId,
  onLogout,
  logoLink,
  links,
}: ExistingCustomerNavBarProps) => {
  const [mobileNavVisible, setMobileNavVisible] = useState(false);
  const isMobile = useMediaLayout({ maxWidth: 900 });

  return (
    <AccountHeader data-testid={dataTestId}>
      <a href={logoLink} title="My Account">
        <img src={`${ASSETS_SVGS_BASE_URL}/raylo-logo.svg`} alt="Raylo Logo" />
      </a>
      <AccountHeaderNav $visible={mobileNavVisible} data-testid={`${dataTestId}-nav`}>
        <ul>
          {links.map(({ title, href }) => (
            <li key={title} className={window.location.pathname === href ? 'active' : undefined}>
              <NavLink href={href} title={title}>
                {title}
              </NavLink>
            </li>
          ))}
          <li>
            <NavLink as="button" onClick={onLogout} data-testid="logout-button">
              Logout
            </NavLink>
          </li>
        </ul>
      </AccountHeaderNav>

      {isMobile && (
        <NavToggle
          onClick={() => setMobileNavVisible((visible) => !visible)}
          data-testid={`${dataTestId}-nav-toggle-${mobileNavVisible ? 'open' : 'closed'}`}
        >
          <NavToggleIcon $active={mobileNavVisible}>
            <span />
          </NavToggleIcon>
        </NavToggle>
      )}
    </AccountHeader>
  );
};
